import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import styles from "./customCreatable.module.scss";

const CustomCreatable = ({
  data = [],
  value = "",
  handleChange,
  onCreateNew,
  creatable,
  loading = false,
  error,
  required,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={styles.createInputDiv}>
        <input
          type="search"
          className={clsx(styles.createInput, error ? styles.inputError : "")}
          value={typeof value === "object" ? value?.label : value}
          onFocus={(e) => {
            setShow(true);
          }}
          onBlur={(e) => {
            setTimeout(() => {
              setShow(false);
            }, 200);
          }}
          required={required}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          style={{
            flex: typeof value === "string" && value?.length && creatable ? "0.8" : "1",
          }}
          {...rest}
        />
        {creatable ? (
          typeof value === "string" && value?.length ? (
            <>
              <button
                className={styles.addBtn}
                style={{
                  flex: typeof value === "string" && value?.length ? "0.2" : "0",
                }}
                onClick={() => {
                  onCreateNew(value);
                  // onCreateNew({ label: value, __isNew__: true });
                }}
              >
                <span>
                  Add
                  <em className="icon ni ni-plus"></em>
                  {/* <MdOutlineAdd /> */}
                </span>
              </button>
              <button
                className={styles.mobBtn}
                style={{
                  flex: typeof value === "string" && value?.length ? "0.2" : "0",
                }}
                onClick={() => {
                  handleChange({ label: value, _isNew_: true });
                }}
              >
                <span>
                  <em className="icon ni ni-plus"></em>

                  {/* <MdOutlineAdd /> */}
                </span>
              </button>
            </>
          ) : null
        ) : null}
        {show && loading ? (
          <div
            className={clsx("scrollbar", styles.dataContainer)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <Spinner
              style={{
                color: "#00aeff",
                width: "25px",
                height: "25px",
              }}
              animation="grow"
            />
          </div>
        ) : data?.length && show ? (
          <div className={clsx("scrollbar", styles.dataContainer)}>
            <ul>
              {data?.map((li, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => {
                      if (li?.isDisabled) return;
                      handleChange({
                        label: li?.label,
                        value: li?.value,
                        country: li?.data?.address?.country ? li?.data?.address?.country : "",
                        province: li?.data?.address?.province ? li?.data?.address?.province?._id : "",
                        city: li?.data?.address?.city ? li?.data?.address?.city?._id : "",
                        neighbourhood: li?.data?.address?.neighbourhood ? li?.data?.address?.neighbourhood?._id : "",
                        address: li?.data?.address?.firstLine,
                        zipcode: li?.data?.address?.zipcode,
                        coordinates: li?.data?.address?.location?.coordinates,
                        data: li?.data,
                      });
                    }}
                    style={{
                      cursor: li?.isDisabled ? "not-allowed" : "pointer",
                      opacity: li?.isDisabled ? 0.5 : 1,
                    }}
                  >
                    <p className={styles.name}>
                      {li?.label} {li?.optional ? <span>{li?.optional}</span> : ""}
                    </p>
                    {li?.subLabel ? <p className={styles.address}>{li?.subLabel}</p> : null}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CustomCreatable;
