import moment from "moment";
import React, { useEffect, useState } from "react";
import { ImNewTab } from "react-icons/im";
import { LuArchiveRestore } from "react-icons/lu";
import { MdInfoOutline } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Spinner, UncontrolledDropdown } from "reactstrap";
import Swal from "sweetalert2";
import PlaceholderImg from "../../assets/placeholder-img.png";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  RSelect,
  Row,
} from "../../components/Component";
import ErrorComponent from "../../components/error/ErrorComponent";
import DotPagination from "../../components/pagination/DotPaginate";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { editDevelopment, handleDevAddReset } from "../../redux/Apislices/developmentSlice";
import {
  deleteMultipleProjects,
  deleteMultipleProjectsPermanently,
  deleteProject,
  deleteProjectPermanently,
  getAllProjects,
  handleProjectAddReset,
  restoreMultipleProjects,
  restoreProject,
} from "../../redux/Apislices/projectSlice";
import { USDollar, getScreenWidth } from "../../utils/Utils";

const saleStatusOptions = [
  { value: "", label: "All" },
  { value: "Pending", label: "Pending" },
  { value: "Registration", label: "Registration" },
  { value: "On Hold", label: "On Hold" },
  { value: "Sold Out", label: "Sold Out" },
];

const statusOptions = [
  { value: "", label: "All" },
  { value: "active", label: "Active" },
  { value: "pending", label: "Pending" },
  { value: "draft", label: "Draft" },
  { value: "on hold", label: "On Hold" },
];

const itemsPerPageOptions = [
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const bulkActionOptions = [
  { value: "", label: "Bulk Action" },
  { value: "archive", label: "Archive" },
  { value: "delete", label: "Delete" },
];

const bulkRestoreOptions = [
  { value: "", label: "Bulk Action" },
  { value: "restore", label: "Restore" },
  { value: "delete", label: "Delete" },
];

const Project = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { development, project, permission } = useSelector((state) => ({
    development: state?.development,
    project: state?.project,
    permission: state?.auth?.profile?.permissions?.community,
  }));

  const { projects: projectData } = project;

  const [selectedData, setSelectedData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");

  const [limit, setLimit] = useState({ value: 25, label: "25" });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);

  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");

  const [bulkAction, setBulkAction] = useState("");

  //debounce getDevelopmentList for search
  useEffect(() => {
    const timer = setTimeout(
      () => {
        dispatch(
          getAllProjects({
            status: typeof status === "object" ? status?.value : status,
            page: currentPage,
            limit: itemPerPage,
            search: onSearchText,
            sort,
          })
        );
      },
      onSearchText ? 800 : 200
    );
    return () => clearTimeout(timer);
  }, [dispatch, currentPage, itemPerPage, onSearchText, sort, status]);

  useEffect(() => {
    if (typeof limit === "object" && limit?.value) {
      setItemPerPage(limit?.value);
    } else {
      setItemPerPage(25);
    }
  }, [limit]);

  // selects all the products
  const selectorCheck = (e) => {
    // add or remove all the items from development?.development?.data to selectedData
    if (e.currentTarget.checked) {
      setSelectedData(projectData?.data?.map((item) => item._id));
    } else {
      setSelectedData([]);
    }
  };

  // selects one product
  const onSelectChange = (e, id) => {
    // add or remove the selected item from selectedData
    if (e.currentTarget.checked) {
      setSelectedData([...selectedData, id]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== id));
    }
  };

  // toggle search input
  const toggle = () => setonSearch(!onSearch);

  // bulk action change
  const onActionText = (e) => {
    setBulkAction(e);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleActiveDevelopment = (data) => {
    let payload = {
      ...data,
      type: data?.type,
    };

    if (data?.status === "active") {
      payload.status = "on hold";
    } else if (data?.status === "on hold" || data?.status === "pending") {
      payload.status = "active";
    }

    dispatch(
      editDevelopment(
        data?.slug,
        payload,
        (res) => {
          dispatch(
            getAllProjects({
              status: typeof status === "object" ? status?.value : status,
              page: currentPage,
              limit: itemPerPage,
              search: onSearchText,
              sort,
            })
          );
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const handleBulkAction = (e) => {
    if (bulkAction?.value === "archive") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Archive",
      }).then((result) => {
        if (result.isConfirmed) {
          handleArchiveMultiple(
            () => {
              Swal.fire("Archived!", "Selected Communities has been archived.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    } else if (bulkAction?.value === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteMultiple(
            () => {
              Swal.fire("Deleted!", "Selected Communities has been deleted.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    } else if (bulkAction?.value === "restore") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Restore",
      }).then((result) => {
        if (result.isConfirmed) {
          handleRestoreMultiple(
            () => {
              Swal.fire("Restored!", "Selected Communities has been restored.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    }
  };

  const handleArchiveMultiple = async (successCB, errorCB) => {
    dispatch(
      deleteMultipleProjects(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            dispatch(
              getAllProjects({
                status: typeof status === "object" ? status?.value : status,
                page: currentPage,
                limit: itemPerPage,
                search: onSearchText,
                sort,
              })
            );
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleRestoreMultiple = async (successCB, errorCB) => {
    dispatch(
      restoreMultipleProjects(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            dispatch(
              getAllProjects({
                status: typeof status === "object" ? status?.value : status,
                page: currentPage,
                limit: itemPerPage,
                search: onSearchText,
                sort,
              })
            );
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleDeleteMultiple = async (successCB, errorCB) => {
    dispatch(
      deleteMultipleProjectsPermanently(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            dispatch(
              getAllProjects({
                status: typeof status === "object" ? status?.value : status,
                page: currentPage,
                limit: itemPerPage,
                search: onSearchText,
                sort,
              })
            );
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleArchive = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Archive",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteProject(
            item?._id,
            (res) => {
              console.log("res: ", res);
              if (res?.success) {
                dispatch(
                  getAllProjects({
                    status: typeof status === "object" ? status?.value : status,
                    page: currentPage,
                    limit: itemPerPage,
                    search: onSearchText,
                    sort,
                  })
                );
              }
              Swal.fire("Archived!", "Community has been archived.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleRestore = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Restore",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          restoreProject(
            item?._id,
            (res) => {
              console.log("res: ", res);
              if (res?.success) {
                dispatch(
                  getAllProjects({
                    status: typeof status === "object" ? status?.value : status,
                    page: currentPage,
                    limit: itemPerPage,
                    search: onSearchText,
                    sort,
                  })
                );
              }
              Swal.fire("Archived!", "Community has been restored.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleDelete = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteProjectPermanently(
            item?._id,
            (res) => {
              if (res?.success) {
                dispatch(
                  getAllProjects({
                    status: typeof status === "object" ? status?.value : status,
                    page: currentPage,
                    limit: itemPerPage,
                    search: onSearchText,
                    sort,
                  })
                );
              }
              Swal.fire("Deleted!", "Community has been deleted.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Community"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Community</BlockTitle>
              <BlockDes className="text-soft">
                {projectData?.data?.length && projectData?.success ? (
                  <p>You have total {projectData?.total?.total} communities.</p>
                ) : null}
              </BlockDes>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        onClick={() => {
                          navigate("/development/add");
                        }}
                        disabled={permission && !permission?.add}
                      >
                        <Icon name="plus"></Icon>
                      </Button>

                      <Button
                        className="toggle d-none btn-outline-primary btn-dim d-md-inline-flex"
                        onClick={() => {
                          dispatch(handleProjectAddReset(null));
                          dispatch(handleDevAddReset({ type: "single" }));
                          navigate("/community/add");
                        }}
                        disabled={permission && !permission?.add}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Community</span>
                      </Button>
                    </li>

                    {(typeof status === "object" && status?.value === "archive") || status === "archive" ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon btn-outline-info d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-info btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                          <span>All Communities</span>
                        </Button>
                      </li>
                    ) : (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-danger btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Archived</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                {/* Bulk Actions */}
                {selectedData.length ? (
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap">
                        <RSelect
                          options={
                            (typeof status === "object" && status?.value === "archive") || status === "archive"
                              ? bulkRestoreOptions
                              : bulkActionOptions
                          }
                          className="w-130px"
                          placeholder="Bulk Action"
                          onChange={(e) => onActionText(e)}
                          value={bulkAction}
                        />
                      </div>
                      <div className="btn-wrap">
                        <span className="d-none d-md-block">
                          <Button
                            disabled={selectedData.length && bulkAction?.value ? false : true}
                            color="info"
                            outline
                            className="btn-dim"
                            onClick={(e) => {
                              if (selectedData.length && bulkAction?.value) {
                                handleBulkAction(e);
                              }
                            }}
                          >
                            Apply
                          </Button>
                        </span>
                        <span className="d-md-none">
                          <Button
                            color="info"
                            outline
                            disabled={selectedData.length && bulkAction?.value ? false : true}
                            className="btn-dim btn-icon"
                            onClick={(e) => {
                              if (selectedData.length && bulkAction?.value) {
                                handleBulkAction(e);
                              }
                            }}
                          >
                            <Icon name="arrow-right"></Icon>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    {/* Search */}
                    <li>
                      <div
                        className="search-content"
                        style={{
                          border: "1px solid #dbdfea",
                          borderRadius: "4px",
                        }}
                      >
                        <Button
                          className="search-back btn-icon toggle-search active"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="search"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="border-transparent form-focus-none form-control"
                          placeholder="Name, City, Neighbourhood, Address"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        {onSearchText ? (
                          <Button
                            className="search-submit btn-icon"
                            onClick={() => {
                              setSearchText("");
                            }}
                          >
                            <Icon name="cross"></Icon>
                          </Button>
                        ) : null}
                      </div>
                    </li>

                    <li className="btn-toolbar-sep"></li>

                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>

                            {/* Filter */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn dropdown-toggle" style={{ padding: "0" }}>
                                  <a href="#" className="btn btn-primary">
                                    <em className="icon ni ni-opt"></em>
                                    <span
                                      style={{
                                        padding: "0",
                                        margin: "0",
                                        paddingLeft: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Filter
                                    </span>
                                  </a>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Developments</span>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      {/* <Col size="6">
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input form-control"
                                            id="hasBalance"
                                          />
                                          <label className="custom-control-label" htmlFor="hasBalance">
                                            {" "}
                                            Have Balance
                                          </label>
                                        </div>
                                      </Col>
                                      <Col size="6">
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input form-control"
                                            id="hasKYC"
                                          />
                                          <label className="custom-control-label" htmlFor="hasKYC">
                                            {" "}
                                            KYC Verified
                                          </label>
                                        </div>
                                      </Col> */}

                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect
                                            options={statusOptions}
                                            onChange={(value) => {
                                              setStatus(value);
                                            }}
                                            value={status}
                                            placeholder="Select"
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Items Per Page</label>
                                          <RSelect
                                            options={itemsPerPageOptions}
                                            onChange={(value) => {
                                              setLimit(value);
                                            }}
                                            value={limit}
                                            placeholder="Select"
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="dropdown-foot between">
                                    {/* <button type="button" className="btn btn-secondary">
                                      Filter
                                    </button> */}
                                    <span></span>

                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setStatus("");
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>

                            {/* Sort */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="sort-line"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("recently-updated");
                                        }}
                                        className={sort === "recently-updated" ? "text text-primary" : ""}
                                      >
                                        <span>Recently Updated</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("recently-added");
                                        }}
                                        className={sort === "recently-added" ? "text text-primary" : ""}
                                      >
                                        <span>Recently Added</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("oldest");
                                        }}
                                        className={sort === "oldest" ? "text text-primary" : ""}
                                      >
                                        <span>Oldest First</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("asc");
                                        }}
                                        className={sort === "asc" ? "text text-primary" : ""}
                                      >
                                        <span>0-9, A-Z</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("desc");
                                        }}
                                        className={sort === "desc" ? "text text-primary" : ""}
                                      >
                                        <span>9-0, Z-A</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={`card-search search-wrap `}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {projectData?.loading ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : projectData?.success && projectData?.data?.length ? (
              <DataTableBody>
                <DataTableHead>
                  {/* Input:Checkbox */}
                  <DataTableRow className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        id={"uid_1"}
                        checked={projectData?.data
                          ?.map((item) => item._id)
                          .every((item) => selectedData.includes(item))}
                        onChange={(e) => selectorCheck(e)}
                      />
                      <label className="custom-control-label" htmlFor="uid_1"></label>
                    </div>
                  </DataTableRow>

                  {/* Name */}
                  <DataTableRow>
                    <span
                      onClick={() => {
                        setSort(sort === "asc" ? "desc" : sort === "desc" ? "asc" : "desc");
                      }}
                      style={{ cursor: "pointer", fontSize: "14px", color: "#5d5d5d", fontWeight: "600" }}
                    >
                      Name
                      <Icon name={sort === "asc" ? "sort-up-fill" : sort === "desc" ? "sort-down-fill" : "sort-v"} />
                    </span>
                  </DataTableRow>

                  {/* Home Type */}
                  <DataTableRow>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#5d5d5d",
                        fontWeight: "600",
                      }}
                    >
                      Home Type
                    </span>
                  </DataTableRow>

                  {/* Completion */}
                  <DataTableRow>
                    <span
                      onClick={() => {
                        setSort(
                          sort === "const-asc" ? "const-desc" : sort === "const-desc" ? "const-asc" : "const-desc"
                        );
                      }}
                      style={{ cursor: "pointer", fontSize: "14px", color: "#5d5d5d", fontWeight: "600" }}
                    >
                      Completion
                      <Icon
                        name={
                          sort === "const-asc" ? "sort-up-fill" : sort === "const-desc" ? "sort-down-fill" : "sort-v"
                        }
                      />
                    </span>
                  </DataTableRow>

                  {/* Listings */}
                  <DataTableRow>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#5d5d5d",
                        fontWeight: "600",
                      }}
                    >
                      Listings
                    </span>
                  </DataTableRow>

                  {/* Beds */}
                  <DataTableRow>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#5d5d5d",
                        fontWeight: "600",
                      }}
                    >
                      Beds
                    </span>
                  </DataTableRow>

                  {/* Area */}
                  <DataTableRow>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#5d5d5d",
                        fontWeight: "600",
                      }}
                    >
                      Area
                    </span>
                  </DataTableRow>

                  {/* Price */}
                  <DataTableRow>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#5d5d5d",
                        fontWeight: "600",
                      }}
                    >
                      Price
                    </span>
                  </DataTableRow>

                  {/* Status */}
                  <DataTableRow>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#5d5d5d",
                        fontWeight: "600",
                      }}
                    >
                      Status
                    </span>
                  </DataTableRow>

                  {/* Created By */}
                  <DataTableRow>
                    <span style={{ fontSize: "14px", color: "#5d5d5d", fontWeight: "600" }}>Created By</span>
                  </DataTableRow>

                  {/* Created On */}
                  <DataTableRow>
                    <span
                      onClick={() => {
                        setSort(sort === "recently-added" ? "oldest" : sort === "oldest" ? "recently-added" : "oldest");
                      }}
                      style={{ cursor: "pointer", fontSize: "14px", color: "#5d5d5d", fontWeight: "600" }}
                    >
                      Created On
                      <Icon
                        name={
                          sort === "recently-added" ? "sort-up-fill" : sort === "oldest" ? "sort-down-fill" : "sort-v"
                        }
                      />
                    </span>
                  </DataTableRow>

                  {/* Action Icon */}
                  <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                </DataTableHead>

                {projectData?.data?.length
                  ? projectData?.data?.map((item) => {
                      return (
                        <DataTableItem key={item._id}>
                          {/* Checkbox */}
                          <DataTableRow className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={item._id + "uid1"}
                                key={Math.random()}
                                checked={selectedData.includes(item._id)}
                                onChange={(e) => onSelectChange(e, item._id)}
                              />
                              <label className="custom-control-label" htmlFor={item._id + "uid1"}></label>
                            </div>
                          </DataTableRow>

                          {/* Name */}
                          <DataTableRow>
                            <div
                              className="tb-product lazy-image"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  // width: "105px",
                                  // height: "67.5px",
                                  marginRight: "10px",
                                }}
                              >
                                <LazyLoadImage
                                  src={
                                    item?.images?.length
                                      ? `${process.env.REACT_APP_IMGURL}${item.images?.[0]}`
                                      : PlaceholderImg
                                  }
                                  alt="development_image"
                                  effect="blur"
                                  className="thumb"
                                  placeholderSrc={PlaceholderImg}
                                  style={{
                                    width: "105px",
                                    height: "67.5px",
                                    borderRadius: "4px",
                                    objectFit: "contain",
                                    border: "1px solid #cecece",
                                    background: "#ededed",
                                  }}
                                />
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}>
                                <p
                                  className="title"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "14px",
                                    padding: "0",
                                    margin: "0",
                                  }}
                                >
                                  <span>{item?.name}</span>
                                  <a
                                    href={`${process.env.REACT_APP_USER_WEB_URL}community/${item?.slug}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <ImNewTab style={{ color: "#00aeff", marginLeft: "5px", fontSize: "14px" }} />
                                  </a>
                                </p>
                                <span style={{}} className="sub-title">
                                  {item?.address?.firstLine}
                                </span>
                                <span style={{}} className="sub-title">
                                  {item?.address?.neighbourhood?.name}
                                </span>
                              </div>
                            </div>
                          </DataTableRow>

                          {/* Home Type */}
                          <DataTableRow>
                            <span className="tb-sub">{item?.homeTypes?.map((li) => li?.name)?.join(", ")}</span>
                          </DataTableRow>

                          {/* Completion */}
                          <DataTableRow>
                            <span className="tb-sub">
                              {item?.construction?.constructionEnd?.year &&
                              item?.construction?.constructionEnd?.year !== "0"
                                ? item?.construction?.constructionEnd?.year
                                : ""}
                            </span>
                          </DataTableRow>

                          {/* Listings */}
                          <DataTableRow>
                            <span className="tb-sub">{item?.count?.assignments ? item?.count?.assignments : 0}</span>
                          </DataTableRow>

                          {/* Beds */}
                          <DataTableRow>
                            {(item?.bedrooms?.min && item?.bedrooms?.max) ||
                            item?.bedrooms?.min === 0 ||
                            item?.bedrooms?.max === 0 ? (
                              <span className="tb-sub">
                                {item?.bedrooms?.min === item?.bedrooms?.max
                                  ? item?.bedrooms?.min
                                  : `${item?.bedrooms?.min} - ${item?.bedrooms?.max}`}
                              </span>
                            ) : null}
                          </DataTableRow>

                          {/* Area */}
                          <DataTableRow>
                            {item?.floorArea?.min || item?.floorArea?.max ? (
                              <span className="tb-sub">
                                {item?.floorArea?.min === item?.floorArea?.max
                                  ? `${item?.floorArea?.min} sqft`
                                  : `${item?.floorArea?.min} - ${item?.floorArea?.max} sqft`}
                              </span>
                            ) : null}
                          </DataTableRow>

                          {/* Price */}
                          <DataTableRow>
                            {item?.price?.price?.from && item?.price?.price?.to ? (
                              <span className="tb-sub">
                                {item?.price?.price?.from === item?.price?.price?.to
                                  ? USDollar(item?.price?.price?.from)
                                  : `${USDollar(item?.price?.price?.from)} - ${USDollar(item?.price?.price?.to)}`}
                              </span>
                            ) : item?.price?.price?.from || item?.price?.price?.to ? (
                              <span className="tb-sub">
                                {item?.price?.price?.from
                                  ? USDollar(item?.price?.price?.from)
                                  : `${USDollar(item?.price?.price?.to)}`}
                              </span>
                            ) : null}
                          </DataTableRow>

                          {/* Status */}
                          <DataTableRow>
                            <p
                              className="tb-sub"
                              style={{
                                color: item?.isDeleted
                                  ? "#FF2A04"
                                  : item?.status === "active"
                                  ? "#00E200"
                                  : item?.status === "sold"
                                  ? "#FF2A04"
                                  : item?.status === "pending"
                                  ? "#FFAF3D"
                                  : item?.status === "draft"
                                  ? "#7B8089"
                                  : item?.status === "on hold"
                                  ? "#285766"
                                  : "",
                                display: "flex",
                                alignItems: "center",
                                gap: "0",
                                padding: "0",
                                margin: "0",
                                textTransform: "capitalize",
                              }}
                            >
                              <span>{item?.isDeleted ? "Archived" : item?.status}</span>
                            </p>
                          </DataTableRow>

                          {/* Created By */}
                          <DataTableRow>
                            <span className="tb-sub">
                              {item?.company?.admin?.byAdmin
                                ? `${item?.company?.admin?.admin?.firstName} ${item?.company?.admin?.admin?.lastName}`
                                : item?.user?.company?.name}
                            </span>
                          </DataTableRow>

                          {/* Created On */}
                          <DataTableRow>
                            <span className="tb-sub">{moment(item?.createdAt).format("DD/MM/YYYY")}</span>
                          </DataTableRow>

                          {/* Icon */}
                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1 my-n1">
                              <li className="mr-n1">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    href="#more"
                                    onClick={(ev) => ev.preventDefault()}
                                    className="dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      {(typeof status === "object" && status?.value === "archive") ||
                                      status === "archive" ? (
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#view"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              handleRestore(item);
                                            }}
                                            disabled={permission && !permission?.edit}
                                          >
                                            {/* <Icon name="archived-fill"></Icon> */}
                                            <LuArchiveRestore
                                              style={{
                                                fontSize: "18px",
                                                marginRight: "15px",
                                                color: "#526484",
                                              }}
                                            />
                                            <span>Restore</span>
                                          </DropdownItem>
                                        </li>
                                      ) : (
                                        <>
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#edit"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                dispatch(handleProjectAddReset(null));
                                                dispatch(handleDevAddReset({ type: "single" }));
                                                navigate(`/community/edit/${item?.slug}`);
                                              }}
                                              disabled={permission && !permission?.edit}
                                            >
                                              <Icon name="edit"></Icon>
                                              <span>Edit this Development</span>
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#view"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                handleActiveDevelopment(item);
                                              }}
                                              disabled={permission && !permission?.edit}
                                            >
                                              <Icon name="eye"></Icon>
                                              <span>
                                                {item?.status === "active" ? "Put On Hold" : "Make It Active"}
                                              </span>
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#view"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                handleArchive(item);
                                              }}
                                              disabled={permission && !permission?.archive}
                                            >
                                              <Icon name="archived-fill"></Icon>
                                              <span>Archive</span>
                                            </DropdownItem>
                                          </li>
                                        </>
                                      )}
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#view"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            handleDelete(item);
                                          }}
                                          disabled={permission && !permission?.delete}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete Permanently</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
            ) : projectData?.success && !projectData?.data?.length ? (
              <div
                className="text-center"
                style={{
                  padding: "20px",
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                <span className="text-silent">No Projects found</span>
              </div>
            ) : projectData?.error ? (
              <ErrorComponent error={projectData?.error} />
            ) : null}

            <div className="card-inner p-0">
              {projectData?.success && projectData?.data?.length ? (
                <DotPagination
                  itemPerPage={projectData?.total?.items_per_page}
                  totalItems={projectData?.total?.total}
                  paginate={paginate}
                  currentPage={currentPage}
                  siblingCount={2}
                  screenWidth={screenWidth}
                />
              ) : null}
            </div>
          </DataTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Project;
