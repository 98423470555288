import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ ...props }) => {
  return (
    <div>
      <Helmet>
        <title>{props.title ? props.title + " | " : ""} Listings Nearby Admin Panel</title>
      </Helmet>
    </div>
  );
};
export default Head;
