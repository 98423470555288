import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";
import { getcityByProvince, updateCity } from "../../redux/Apislices/citySlice";
import { getCountry, updateCountry } from "../../redux/Apislices/countrySlice";
import { getprovinceByCountry, updateProvince } from "../../redux/Apislices/provinceSlice";
import { USDollar } from "../../utils/Utils";
import { Button, Icon } from "../Component";
import styles from "./accordion.module.scss";
import { getneighbourhoodByCity, updateNeighbourhood } from "../../redux/Apislices/neighbourhoodSlice";

const AssociateCostAccordion = () => {
  const dispatch = useDispatch();
  const { country, city, province, neighbourhood } = useSelector((state) => ({
    country: state?.country?.countries,
    province: state?.province?.provinceByCountry,
    city: state?.city?.cityByProvince,
    neighbourhood: state?.neighbourhood?.neighbourhoodByCity,
  }));

  const [countryId, setCountryId] = useState(null);
  const [provinceId, setProvinceId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [neighbourhoodId, setNeighbourhoodId] = useState(null);

  const [view, setView] = useState(false);
  const [editData, setEditData] = useState(null);
  const [type, setType] = useState("");

  const [price, setPrice] = useState("");

  const { errors, register, handleSubmit } = useForm();

  const toggle = (data, type) => {
    if (data) {
      setEditData(data);
      setType(type);
      setPrice(data?.associateCost);
      setView(true);
    } else {
      setEditData(null);
      setPrice(null);
      setView(false);
    }
  };

  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getprovinceByCountry(countryId));
  }, [countryId, dispatch]);

  useEffect(() => {
    dispatch(getcityByProvince(provinceId));
  }, [provinceId, dispatch]);

  useEffect(() => {
    dispatch(getneighbourhoodByCity(cityId));
  }, [cityId, dispatch]);

  const onFormSubmit = async (e) => {
    const data = { associateCost: +price };
    if (type === "country") {
      dispatch(
        updateCountry(
          editData?._id,
          data,
          (res) => {
            dispatch(getCountry);
            toast.success("Updated successfully!");
            toggle();
          },
          (res) => {
            toast.error("Something went wrong, try again!");
            toggle();
          }
        )
      );
    } else if (type === "province") {
      dispatch(
        updateProvince(
          editData?._id,
          data,
          (res) => {
            dispatch(getprovinceByCountry(res?.data?.country));
            toast.success("Updated successfully!");
            toggle();
          },
          (res) => {
            toast.error("Something went wrong, try again!");
            toggle();
          }
        )
      );
    } else if (type === "city") {
      dispatch(
        updateCity(
          editData?._id,
          data,
          (res) => {
            dispatch(getcityByProvince(res?.data?.province));
            toggle();
            toast.success("Updated successfully!");
          },
          (res) => {
            toast.error("Something went wrong, try again!");
            toggle();
          }
        )
      );
    } else if (type === "neighbourhood") {
      dispatch(
        updateNeighbourhood(
          editData?._id,
          data,
          (res) => {
            dispatch(getneighbourhoodByCity(res?.data?.city));
            toggle();
            toast.success("Updated successfully!");
          },
          (res) => {
            toast.error("Something went wrong, try again!");
            toggle();
          }
        )
      );
    }
  };

  return (
    <div>
      <div className={styles.accordion}>
        {country?.loading ? (
          <div className="p-2">Loading...</div>
        ) : country?.data?.length ? (
          country?.data?.map((item, index) => (
            <div
              key={item?._id}
              className={clsx(`${styles.accordion_item} ${item?._id === countryId ? styles.active : ""}`)}
            >
              <div
                className={clsx(`${styles.accordion_title}`)}
                onClick={() => {
                  if (countryId) {
                    setCountryId(null);
                  } else {
                    setCountryId(item?._id);
                  }
                }}
              >
                <div className={clsx(`${styles.titleDiv}`)}>
                  <span>{item?.name}</span>
                  {item?.associateCost ? <span>({USDollar(item?.associateCost)})</span> : null}
                </div>
                <div className={clsx(`${styles.iconDiv}`)}>
                  <Icon
                    name="edit"
                    className={`${styles.icon}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggle(item, "country");
                    }}
                  />
                  <Icon
                    name="chevron-down"
                    className={`${styles.icon} ${item?._id === countryId ? styles.active : ""}`}
                  />
                </div>
              </div>
              <div className={`${styles.accordion_content} ${item?._id === countryId ? styles.active : ""}`}>
                <div className={styles.accordion}>
                  {province?.loading ? (
                    <div className="p-2">Loading...</div>
                  ) : province?.data?.length ? (
                    province?.data?.map((provinceItem, index) => (
                      <div
                        key={provinceItem?._id}
                        className={clsx(
                          `${styles.accordion_item} ${provinceItem?._id === provinceId ? styles.active : ""}`
                        )}
                      >
                        <div
                          className={clsx(`${styles.accordion_title}`)}
                          onClick={() => {
                            if (provinceId) {
                              setProvinceId(null);
                            } else {
                              setProvinceId(provinceItem?._id);
                            }
                          }}
                        >
                          <div className={clsx(`${styles.titleDiv}`)}>
                            <span>{provinceItem?.name}</span>
                            {provinceItem?.associateCost ? <span>({USDollar(provinceItem?.associateCost)})</span> : null}
                          </div>
                          <div className={clsx(`${styles.iconDiv}`)}>
                            <Icon
                              name="edit"
                              className={`${styles.icon}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggle(provinceItem, "province");
                              }}
                            />
                            <Icon
                              name="chevron-down"
                              className={`${styles.icon} ${provinceItem?._id === provinceId ? styles.active : ""}`}
                            />
                          </div>
                        </div>
                        <div
                          className={`${styles.accordion_content} ${
                            provinceItem?._id === provinceId ? styles.active : ""
                          }`}
                        >
                          <div className={styles.accordion}>
                            {city?.loading ? (
                              <div className="p-2">Loading...</div>
                            ) : city?.data?.length ? (
                              city?.data?.map((cityItem, index) => (
                                <div
                                  key={cityItem?._id}
                                  className={clsx(
                                    `${styles.accordion_item} ${cityItem?._id === cityId ? styles.active : ""}`
                                  )}
                                >
                                  <div
                                    className={clsx(`${styles.accordion_title}`)}
                                    onClick={() => {
                                      if (cityId) {
                                        setCityId(null);
                                      } else {
                                        setCityId(cityItem?._id);
                                      }
                                    }}
                                  >
                                    <div className={clsx(`${styles.titleDiv}`)}>
                                      <span>{cityItem?.name}</span>
                                      {cityItem?.associateCost ? <span>({USDollar(cityItem?.associateCost)})</span> : null}
                                    </div>
                                    <div className={clsx(`${styles.iconDiv}`)}>
                                      <Icon
                                        name="edit"
                                        className={`${styles.icon}`}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggle(cityItem, "city");
                                        }}
                                      />
                                      <Icon
                                        name="chevron-down"
                                        className={`${styles.icon} ${cityItem?._id === cityId ? styles.active : ""}`}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={`${styles.accordion_content} ${
                                      cityItem?._id === cityId ? styles.active : ""
                                    }`}
                                  >
                                    <div className={styles.accordion}>
                                      {neighbourhood?.loading ? (
                                        <div className="p-2">Loading...</div>
                                      ) : neighbourhood?.data?.length ? (
                                        neighbourhood?.data?.map((neighbourhoodItem, index) => (
                                          <div
                                            key={neighbourhoodItem?._id}
                                            className={clsx(
                                              `${styles.accordion_item} ${
                                                neighbourhoodItem?._id === neighbourhoodId ? styles.active : ""
                                              }`
                                            )}
                                          >
                                            <div className={clsx(`${styles.accordion_title}`)}>
                                              <div className={clsx(`${styles.titleDiv}`)}>
                                                <span>{neighbourhoodItem?.name}</span>
                                                {neighbourhoodItem?.associateCost ? (
                                                  <span>({USDollar(neighbourhoodItem?.associateCost)})</span>
                                                ) : null}
                                              </div>
                                              <div className={clsx(`${styles.iconDiv}`)}>
                                                <Icon
                                                  name="edit"
                                                  className={`${styles.icon}`}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggle(neighbourhoodItem, "neighbourhood");
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className={`${styles.accordion_content} ${
                                                neighbourhoodItem?._id === neighbourhoodId ? styles.active : ""
                                              }`}
                                            ></div>
                                          </div>
                                        ))
                                      ) : city?.success && !city?.data?.length ? (
                                        <div className="p-2">No data found</div>
                                      ) : (
                                        <div className="p-2">Something went wrong</div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : city?.success && !city?.data?.length ? (
                              <div className="p-2">No data found</div>
                            ) : (
                              <div className="p-2">Something went wrong</div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : province?.success && !province?.data?.length ? (
                    <div className="p-2">No data found</div>
                  ) : (
                    <div className="p-2">Something went wrong</div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : country?.success && !country?.data?.length ? (
          <div className="p-2">No data found</div>
        ) : (
          <div className="p-2">Something went wrong</div>
        )}
      </div>
      <Modal
        isOpen={view}
        toggle={() => {
          toggle();
        }}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" className="close">
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                toggle();
              }}
            ></Icon>
          </a>
          <div>
            <h4>Edit Associate Subscription for {editData?.name}</h4>
            <form action="" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="row my-3">
                <div className="col-md-12 mt-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="basic-url">
                      Price
                    </label>
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon3">
                            $
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          id="price"
                          placeholder="Enter associate cost"
                          name="price"
                          value={price}
                          onChange={(e) => setPrice(e.target?.value)}
                          ref={register({ required: "Price is required" })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
                <Button color="primary" type="submit">
                  Save
                </Button>
                <Button color="secondary" type={"button"} onClick={() => toggle()}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AssociateCostAccordion;
