import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getUsers = createAsyncThunk("user/usersList", async (query) => {
  try {
    const response = await getMethod(`/api/admin/users`, query);
    return response;
  } catch (error) {
    throw error;
  }
});
export const getAdminPermission = createAsyncThunk("user/adminPermission", async ({ user, query }) => {
  try {
    const response = await getMethod(`/api/admin/permission/${user}`, query);
    return response;
  } catch (error) {
    throw error;
  }
});

let initialState = {
  loading: false,
  success: false,
  users: {
    loading: false,
    success: false,
    data: null,
    total: null,
    error: false,
  },
  user: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  permission: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  error: false,
};

export const userSlice = createSlice({
  name: "user",

  initialState,

  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccessful: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    handleResetUser: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.users = initialState.users;
      state.user = initialState.user;
      state.permission = initialState.permission;
    },
    handleResetPermission: (state) => {
      state.user = initialState.user;
      state.permission = initialState.permission;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.users.loading = true;
      state.users.success = false;
      state.users.data = null;
      state.users.total = null;
      state.users.error = false;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users.loading = false;
      state.users.success = true;
      state.users.data = action.payload.data;
      state.users.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
      state.users.error = false;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.users.loading = false;
      state.users.success = false;
      state.users.data = null;
      state.users.total = null;
      state.users.error = true;
    });

    builder.addCase(getAdminPermission.pending, (state) => {
      state.permission.loading = true;
      state.permission.success = false;
      state.permission.data = null;
      state.permission.error = false;
    });
    builder.addCase(getAdminPermission.fulfilled, (state, action) => {
      state.permission.loading = false;
      state.permission.success = true;
      state.permission.data = action.payload.data;
      state.permission.error = false;
    });
    builder.addCase(getAdminPermission.rejected, (state) => {
      state.permission.loading = false;
      state.permission.success = false;
      state.permission.data = null;
      state.permission.error = true;
    });
  },
});

export const { isLoading, isError, isSuccessful, handleResetUser, handleResetPermission } = userSlice.actions;

export default userSlice.reducer;

export const editUser = (id, data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await putMethod(`api/admin/users/edit/${id}`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const updateOwnProfile = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await putMethod(`api/users/profile`, data).then((response) => {
    if (response?.success) successCB(response);
    else errorCB(response);
  });
};

export const deleteUser = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await deleteMethod(`api/admin/users/delete/${id}`).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const deleteMultipleUser = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await postMethod(`api/admin/users/delete-multiple`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const restoreUser = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await putMethod(`api/admin/users/restore/${id}`).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const restoremultipleUser = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await postMethod(`api/admin/users/restore-multiple`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const deleteUserPermanently = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await postMethod(`api/admin/users/delete-permanently/${id}`).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const deleteMultipleUserPermanently = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await postMethod(`api/admin/users/delete-multiple-permanently`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};
export const addAdminTeam = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await postMethod(`api/admin/team/add`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};
export const editAdminPermissions = (id, data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await putMethod(`api/admin/permission/${id}`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const handleConfirmVip = (id, data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await putMethod(`api/admin/users/vip-agent/${id}`, data).then((response) => {
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  });
};

export const createUser = (role, data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await postMethod(`api/admin/users/add/${role}`, data).then((response) => {
    if (response?.success) successCB(response);
    else errorCB(response);
  });
};
